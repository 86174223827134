import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import {
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useOutletContext,
} from "react-router-dom";
import Countdown from "react-countdown";
import { HeyGGFooter } from "@heygoodgame/common";

import "@bcgov/bc-sans/css/BCSans.css";

import { WordOfDayResponse, getWordOfDay, randomWordOfDay } from "../lib/words";
import { isValidLevel, Level } from "../utils/isValidLevel";
import { SettingsModal } from "../components/modals/SettingsModal";
import { InfoModal } from "../components/modals/InfoModal";
import MathlerTitle from "../components/svgs/MathlerTitle";
import MathlerIcon from "../components/svgs/MathlerIcon";
import Toggle, { PRACTICE } from "../components/toggle";
import HowToPlay from "../components/howToPlay";
import useGameMode from "../hooks/useGameMode";
import Banner from "../components/banner";
import SEO from "../components/seo";
import useGoogleAnalyticsPageTracking from "../hooks/useGoogleAnalyticsPageTracking";
import useDarkMode from "../hooks/useDarkMode";
import NavbarLink from "../components/NavbarLink";
import SidebarLeft from "../components/publift/SidebarLeft";
import SidebarRight from "../components/publift/SidebarRight";
import HeaderAd from "../components/publift/HeaderAd";
import useZoneRegistration from "../components/publift/useZoneRegistration";
import IncontentBottom from "../components/publift/InContentBottom";
import MobIncontent from "../components/publift/MobIncontent";

export const ALERT_TIME_MS = 2000;
const colorblindmode = localStorage.getItem("colorblindmode");
const darkmode = localStorage.getItem("darkmode");

if (colorblindmode === "colorblind") {
  document.body.classList.add("colorblind");
}

if (darkmode === "darkmode") {
  document.body.classList.add("darkmode");
}

type ContextType = {
  level: string;
  solution: string;
  solutionIndex: number;
  solutionMath: number;
  tomorrow: number;
  isPractice: boolean;
  isStatsModalOpen: boolean;
  setIsStatsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isGameWon: boolean;
  setIsGameWon: React.Dispatch<React.SetStateAction<boolean>>;
  isGameLost: boolean;
  setIsGameLost: React.Dispatch<React.SetStateAction<boolean>>;
  isWinModalOpen: boolean;
  setIsWinModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type LoaderResponse = WordOfDayResponse & { isPractice: boolean };

export function loader({
  params,
  request,
}: LoaderFunctionArgs): LoaderResponse {
  const url = new URL(request.url);
  const isPractice = url.searchParams.get(PRACTICE);
  const level = isValidLevel(params.level) ? params.level : "normal";

  if (isPractice === "true") {
    const practiceLevel = localStorage.getItem(`practiceLevel-${level}`);
    if (practiceLevel) {
      return JSON.parse(practiceLevel);
    } else {
      const payload = { ...randomWordOfDay(level), isPractice: true };
      localStorage.setItem(`practiceLevel-${level}`, JSON.stringify(payload));
      return payload;
    }
  } else {
    return { ...getWordOfDay(level), isPractice: false };
  }
}

function Root() {
  const { level, solution, solutionIndex, solutionMath, tomorrow, isPractice } =
    useLoaderData() as LoaderResponse;
  const [isGameWon, setIsGameWon] = useGameMode(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useGameMode(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useGameMode(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useGameMode(false);
  const [isWinModalOpen, setIsWinModalOpen] = useGameMode(false);

  const [isGameLost, setIsGameLost] = useGameMode(false);

  useGoogleAnalyticsPageTracking();
  useZoneRegistration();

  const isDarkMode = useDarkMode();

  (window as any).mathlerTomorrow = tomorrow;

  return (
    <div>
      <SEO
        title={`A free daily math puzzle game ${
          level ? `(${level} level)` : ""
        }`}
        description={
          "Play free daily Mathler games online from Easy to Killer level on mathler.com. Inspired by Wordle, test your math skills with this math based web game. "
        }
      />

      <div className="sticky-pushdown">
        <HeaderAd />
      </div>

      <div className="sticky-leaderboard-footer">
        <div className="inner">{/* Publift inject the sticky footer */}</div>
      </div>

      <div className="flex justify-around flex-row">
        <div className="sticky-siderail-left">
          <div className="inner">
            <SidebarLeft />
          </div>
        </div>
      </div>

      <div
        className="mx-auto sm:px-6 lg:px-8 text-center pt-2 md:pb-32"
        style={{ ...(!isDarkMode ? { backgroundColor: "#F5F7FA" } : {}) }}
      >
        <div className="w-[361px] h-[69px] mx-auto items-center my-4">
          <div className="flex mx-auto items-center w-[350px] h-[24px] justify-between mb-2">
            <QuestionMarkCircleIcon
              className="h-7 w-7 cursor-pointer"
              onClick={() => setIsInfoModalOpen(true)}
            />
            <div className="flex items-center ml-8">
              <MathlerIcon />
              <div className="ml-1">
                <MathlerTitle />
              </div>
            </div>
            <div className="flex items-center">
              <Cog6ToothIcon
                className="h-7 w-7 cursor-pointer"
                onClick={() => setIsSettingsModalOpen(true)}
              />
              <ChartBarIcon
                className="h-7 w-7 cursor-pointer"
                onClick={() => {
                  setIsStatsModalOpen(true);
                }}
              />
            </div>
          </div>
          <div className="flex w-90 mx-auto items-center mb-2">
            <h2 className="text-lg grow text-center">
              Find the hidden calculation{" "}
              <span className="bg-[#FCEB4E] no-darkmode rounded px-0.5">
                that equals {solutionMath}
              </span>
            </h2>
          </div>
        </div>

        <div className="flex w-[361px] mx-auto items-center justify-between mb-1">
          <NavbarLink />
          <div className="flex">
            <Toggle checked={isPractice} />
            <label className="toggle-label">Practice</label>
          </div>
        </div>

        {(isGameLost || (isGameWon && !isPractice)) && (
          <div className="flex w-90 mx-auto items-center mb-2">
            <h2 className="text-md grow text-center">
              New puzzle in <Countdown date={tomorrow} daysInHours={true} />
            </h2>
          </div>
        )}
        <Outlet
          context={{
            level,
            solution,
            solutionIndex,
            solutionMath,
            tomorrow,
            isPractice,
            isStatsModalOpen,
            setIsStatsModalOpen,
            isGameWon,
            setIsGameWon,
            isGameLost,
            setIsGameLost,
            isWinModalOpen,
            setIsWinModalOpen,
          }}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          setIsOpen={setIsInfoModalOpen}
          level={level as Level}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
        />

        <div className="below-game-footer-mobile">
          <div className="inner">
            <MobIncontent />
          </div>
        </div>
      </div>

      <div className="sticky-siderail-right">
        <div className="inner">
          <SidebarRight />
        </div>
      </div>

      <Banner />

      <div className="w-90 mx-auto items-center feedback">
        <a href="/feedback.html" target="_blank">
          Have feedback?
        </a>
      </div>

      <HowToPlay />

      <div className="incontent-bottom">
        <IncontentBottom />
      </div>

      <div className="heygg-body">
        <HeyGGFooter copyRightStartDate={"2022"} />
      </div>
    </div>
  );
}

export function useGameState() {
  return useOutletContext<ContextType>();
}

export default Root;
