import { useEffect } from "react";

export default function useZoneRegistration() {
  useEffect(() => {
    const w = window as any;
    const fusetag = w.fusetag || (w.fusetag = { que: [] });

    fusetag.que.push(function () {
      //// Defer the first auction until FuseIds are registered
      fusetag.pageInit({
        blockingFuseIds: [
          "23148777297",
          "23148777495",
          "23148777303",
          "23148777498",
          "23148777300",
        ],
      });
    });
  }, []);
}
