import { BaseModal } from "./BaseModal";
import { Level } from "../../utils/isValidLevel";
import { useState } from "react";
import { getWordOfDay } from "../../lib/words";
import capitalize from "../../utils/capitalize";
import { useEffectAfterMigration } from "../../hooks/useEffectAfterMigration";
import { StatBar } from "../stats/StatBar";
import { Histogram } from "../stats/Histogram";
import { GameStats } from "../../lib/localStorage";
import ShareButton from "../buttons/ShareButton";
import PracticeButton from "../buttons/PracticeButton";

const levels = ["easy", "normal", "hard", "killer"];

export default function WinModal({
  level,
  isOpen,
  handleClose,
  gameStats,
  isGameWon,
  isPracticeGame,
  handleShare,
  guesses,
  isGameLost,
  solutionIndex,
  solution,
}: {
  level: Level;
  solution: string;
  isOpen: boolean;
  handleClose: () => void;
  gameStats: GameStats;
  isGameWon: boolean;
  isPracticeGame: boolean;
  handleShare: () => void;
  guesses: string[][];
  isGameLost: boolean;
  solutionIndex: number;
}) {
  const title = !isGameLost ? "Hey, Good Game!" : "Almost had it!";

  return (
    <BaseModal title={title} isOpen={isOpen} handleClose={handleClose as any}>
      <div className="flex flex-col items-center gap-2 my-4">
        <p className="text-center font-sans text-base font-bold leading-tight">
          We launched a new word game!{" "}
        </p>
        <a
          target="_blank"
          href="https://imsqueezy.com/"
          rel="noreferrer"
          className="font-bold"
        >
          <div className="rounded-full border border-2 border-slate-900 bg-[#FFEB70] w-32 h-7 flex items-center justify-center text-slate-900">
            Play Squeezy
          </div>
        </a>
      </div>
      <StatBar gameStats={gameStats} />
      {gameStats?.totalGames > 0 && (
        <>
          <h4 className="text-lg leading-6 font-bold">Guess Distribution</h4>
          <Histogram
            gameStats={gameStats}
            guesses={guesses}
            isGameWon={isGameWon}
          />
        </>
      )}

      <div className="flex flex-col">
        <TryNextLevel level={level} isPracticeGame={isPracticeGame} />
        <PracticeButton
          isPractice={isPracticeGame}
          isGameLost={isGameLost}
          level={level}
        />
        <ShareButton
          guesses={guesses}
          isGameLost={isGameLost}
          solutionIndex={solutionIndex}
          solution={solution}
          handleShare={handleShare}
          isGameWon={isGameWon}
          isPracticeGame={isPracticeGame}
          level={level}
        />

        <a className="newsletter-link" href="https://www.hey.gg/newsletter">
          Get game tips, insights, and updates every Friday
        </a>
      </div>
    </BaseModal>
  );
}

function TryNextLevel({
  level,
  isPracticeGame,
}: {
  level: Level;
  isPracticeGame: boolean;
}) {
  const [nextLevelToTry, setNextLevelToTry] = useState<string | null>(null);

  useEffectAfterMigration(() => {
    const startIndex = levels.indexOf(level);
    const arrayOfLevels = [
      ...levels.slice(startIndex),
      ...levels.slice(0, startIndex),
    ];

    let lookUpFrom = 0;
    let validNextLevel = false;
    let nextLevel = arrayOfLevels[lookUpFrom];

    while (validNextLevel === false && lookUpFrom < arrayOfLevels.length) {
      const gameState = localStorage.getItem(`gameState-${nextLevel}`);

      if (gameState) {
        const gameStateObj = JSON.parse(gameState);

        if (gameStateObj?.solution) {
          const { solution } = getWordOfDay(nextLevel as Level);

          if (solution !== gameStateObj.solution) {
            validNextLevel = true;
            setNextLevelToTry(nextLevel);
            return;
          }
        }
      } else {
        validNextLevel = true;
        setNextLevelToTry(nextLevel);
        return;
      }

      nextLevel = arrayOfLevels[++lookUpFrom];
    }
  }, [level]);

  if (!nextLevelToTry && isPracticeGame) return <></>;
  return (
    <a
      type="button"
      className="align-center w-full py-4 rounded-md border border-1 border-black  text-center font-sans text-lg font-extrabold mb-2"
      href={
        nextLevelToTry
          ? `${window.location.origin}/${nextLevelToTry}`
          : `${window.location.origin}/${level}/?practice=true`
      }
    >
      {nextLevelToTry
        ? `Try ${capitalize(nextLevelToTry)} Mathler`
        : "Try a Practice Game"}
    </a>
  );
}
